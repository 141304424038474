
import BaseCard from '@/components/commonComponents/BaseCard.vue';
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import { FetchPolicy } from 'apollo-client';
import DataTableHandler from '@/components/DataTable/DataTable';
import EventBus from '@/event-bus';
import Message from '@/components/mixins/Message.vue';
import DataTable from '@/components/DataTable/DataTable.vue';

// Lazy loading imports
const ListAddFields = () => import(
  /* webpackChunkName: 'list-add-fields' */
  /* webpackPrefetch: false */
  '@/components/commonComponents/ListAddFields.vue'
);

@Component({
  name: 'FilesBlock',
  components: {
    DataTable,
    BaseCard,
    ListAddFields,
  },
})
export default class FilesBlock extends Vue {
  @Prop({ required: true })
  private readonly blockId!: number;

  @Prop({ required: true })
  protected readonly navigationSlug!: string;

  @Prop({ required: true })
  protected readonly dossierTypeSlug!: string;

  @Prop({ required: true })
  protected readonly dossierId!: number;

  @Prop({ required: true })
  private readonly title!: string;

  @Prop()
  private readonly context?: string;

  private dataTable = new DataTableHandler();

  private loading = false;

  private hasAddFields = false;

  mounted(): void {
    const forceRerender = () => this.fetchData('network-only');

    EventBus.$on('forceRerender', forceRerender);

    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('forceRerender', forceRerender);
    });
  }

  private fetchData(fetchPolicy: FetchPolicy = 'cache-first') {
    this.loading = true;

    const variables = {
      ...this.dataTable.getQueryVariables(),
      dossierId: this.dossierId,
      blockId: this.blockId,
      context: this.context,
      navigationSlug: this.navigationSlug,
      dossierTypeSlug: this.dossierTypeSlug,
    };

    import('@/graphql/queries/dossier-type-list-block-by-id')
      .then(({ default: query }) => this.$apollo.query({
        fetchPolicy,
        query,
        variables,
      }))
      .then((response) => {
        this.dataTable.handleData(response.data);
        this.hasAddFields = response.data.list.dataGridInfo.metadata['list-settings']?.quick_add ?? false;
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
        this.$emit('rendered');
      });
  }
}
